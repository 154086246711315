var exports = {};
/**
 * In addition to holding all of the input data for this lookup, this class also<br>
 *     will contain the result of the lookup after it comes back from the API.
 *     @see "https://www.smarty.com/docs/cloud/us-street-api#input-fields"
 */
class Lookup {
  constructor(street, street2, secondary, city, state, zipCode, lastLine, addressee, urbanization, match, maxCandidates, inputId, format) {
    this.street = street;
    this.street2 = street2;
    this.secondary = secondary;
    this.city = city;
    this.state = state;
    this.zipCode = zipCode;
    this.lastLine = lastLine;
    this.addressee = addressee;
    this.urbanization = urbanization;
    this.match = match;
    this.maxCandidates = maxCandidates;
    this.inputId = inputId;
    this.format = format;
    this.result = [];
  }
}
exports = Lookup;
export default exports;