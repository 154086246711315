import _Request from "../Request";
import _Errors from "../Errors";
import _Candidate from "./Candidate";
import _buildInputData from "../util/buildInputData";
import _apiToSDKKeyMap from "../util/apiToSDKKeyMap";
var exports = {};
const Request = _Request;
const Errors = _Errors;
const Candidate = _Candidate;
const buildInputData = _buildInputData;
const keyTranslationFormat = _apiToSDKKeyMap.internationalStreet;

/**
 * This client sends lookups to the Smarty International Street API, <br>
 *     and attaches the results to the appropriate Lookup objects.
 */
class Client {
  constructor(sender) {
    this.sender = sender;
  }
  send(lookup) {
    if (typeof lookup === "undefined") throw new Errors.UndefinedLookupError();
    let request = new Request();
    request.parameters = buildInputData(lookup, keyTranslationFormat);
    return new Promise((resolve, reject) => {
      this.sender.send(request).then(response => {
        if (response.error) reject(response.error);
        resolve(attachLookupCandidates(response, lookup));
      }).catch(reject);
    });
    function attachLookupCandidates(response, lookup) {
      response.payload.map(rawCandidate => {
        lookup.result.push(new Candidate(rawCandidate));
      });
      return lookup;
    }
  }
}
exports = Client;
export default exports;