import _Candidate from "./Candidate";
import _Lookup from "./Lookup";
import _Batch from "../Batch";
import _Errors from "../Errors";
import _sendBatch from "../util/sendBatch";
import _apiToSDKKeyMap from "../util/apiToSDKKeyMap";
var exports = {};
const Candidate = _Candidate;
const Lookup = _Lookup;
const Batch = _Batch;
const UndefinedLookupError = _Errors.UndefinedLookupError;
const sendBatch = _sendBatch;
const keyTranslationFormat = _apiToSDKKeyMap.usStreet;

/**
 * This client sends lookups to the Smarty US Street API, <br>
 *     and attaches the results to the appropriate Lookup objects.
 */
class Client {
  constructor(sender) {
    this.sender = sender;
  }

  /**
   * Sends up to 100 lookups for validation.
   * @param data may be a Lookup object, or a Batch which must contain between 1 and 100 Lookup objects
   * @throws SmartyException
   */
  send(data) {
    const dataIsBatch = data instanceof Batch;
    const dataIsLookup = data instanceof Lookup;
    if (!dataIsLookup && !dataIsBatch) throw new UndefinedLookupError();
    let batch;
    if (dataIsLookup) {
      if (data.maxCandidates == null && data.match == "enhanced") data.maxCandidates = 5;
      batch = new Batch();
      batch.add(data);
    } else {
      batch = data;
    }
    return sendBatch(batch, this.sender, Candidate, keyTranslationFormat);
  }
}
exports = Client;
export default exports;