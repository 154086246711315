import _Errors from "./Errors";
var exports = {};
const Errors = _Errors;
class StatusCodeSender {
  constructor(innerSender) {
    this.sender = innerSender;
  }
  send(request) {
    return new Promise((resolve, reject) => {
      this.sender.send(request).then(resolve).catch(error => {
        switch (error.statusCode) {
          case 500:
            error.error = new Errors.InternalServerError();
            break;
          case 503:
            error.error = new Errors.ServiceUnavailableError();
            break;
          case 504:
            error.error = new Errors.GatewayTimeoutError();
            break;
          default:
            error.error = new Errors.DefaultError(error && error.payload && error.payload.errors[0] && error.payload.errors[0].message);
        }
        reject(error);
      });
    });
  }
}
exports = StatusCodeSender;
export default exports;