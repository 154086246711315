import _package from "../package.json";
var exports = {};
class AgentSender {
  constructor(innerSender) {
    this.sender = innerSender;
  }
  send(request) {
    request.parameters.agent = "smarty (sdk:javascript@" + _package.version + ")";
    return new Promise((resolve, reject) => {
      this.sender.send(request).then(resolve).catch(reject);
    });
  }
}
exports = AgentSender;
export default exports;