import _Response from "../Response.js";
var exports = {};
const Response = _Response;
function buildSmartyResponse(response, error) {
  if (response) return new Response(response.status, response.data, response.error, response.headers);
  return new Response(undefined, undefined, error);
}
exports = {
  buildSmartyResponse
};
export default exports;