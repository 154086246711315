import _Result from "./Result";
var exports = {};
const Result = _Result;

/**
 * The SmartyResponse contains the response from a call to the US Reverse Geo API.
 */
class Response {
  constructor(responseData) {
    this.results = [];
    if (responseData) responseData.results.map(rawResult => {
      this.results.push(new Result(rawResult));
    });
  }
}
exports = Response;
export default exports;