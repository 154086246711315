import _Request from "../Request";
import _Response from "./Response";
import _buildInputData from "../util/buildInputData";
import _apiToSDKKeyMap from "../util/apiToSDKKeyMap";
import _Errors from "../Errors.js";
var exports = {};
const Request = _Request;
const Response = _Response;
const buildInputData = _buildInputData;
const keyTranslationFormat = _apiToSDKKeyMap.usReverseGeo;
const {
  UndefinedLookupError
} = _Errors;

/**
 * This client sends lookups to the Smarty US Reverse Geo API, <br>
 *     and attaches the results to the appropriate Lookup objects.
 */
class Client {
  constructor(sender) {
    this.sender = sender;
  }
  send(lookup) {
    if (typeof lookup === "undefined") throw new UndefinedLookupError();
    let request = new Request();
    request.parameters = buildInputData(lookup, keyTranslationFormat);
    return new Promise((resolve, reject) => {
      this.sender.send(request).then(response => {
        if (response.error) reject(response.error);
        resolve(attachLookupResults(response, lookup));
      }).catch(reject);
    });
    function attachLookupResults(response, lookup) {
      lookup.response = new Response(response.payload);
      return lookup;
    }
  }
}
exports = Client;
export default exports;