import _InputData from "../InputData";
var exports = {};
const InputData = _InputData;
exports = (lookup, keyTranslationFormat) => {
  let inputData = new InputData(lookup);
  for (let key in keyTranslationFormat) {
    inputData.add(key, keyTranslationFormat[key]);
  }
  return inputData.data;
};
export default exports;