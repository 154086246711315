import _Errors from "../Errors";
import _Request from "../Request";
import _Result from "./Result";
import _buildInputData from "../util/buildInputData";
import _apiToSDKKeyMap from "../util/apiToSDKKeyMap";
var exports = {};
const Errors = _Errors;
const Request = _Request;
const Result = _Result;
const buildInputData = _buildInputData;
const keyTranslationFormat = _apiToSDKKeyMap.usExtract;

/**
 * This client sends lookups to the Smarty US Extract API, <br>
 *     and attaches the results to the Lookup objects.
 */
class Client {
  constructor(sender) {
    this.sender = sender;
  }
  send(lookup) {
    if (typeof lookup === "undefined") throw new Errors.UndefinedLookupError();
    let request = new Request(lookup.text);
    request.parameters = buildInputData(lookup, keyTranslationFormat);
    return new Promise((resolve, reject) => {
      this.sender.send(request).then(response => {
        if (response.error) reject(response.error);
        lookup.result = new Result(response.payload);
        resolve(lookup);
      }).catch(reject);
    });
  }
}
exports = Client;
export default exports;