import _Errors from "../Errors";
import _Request from "../Request";
import _Suggestion from "./Suggestion";
import _buildInputData from "../util/buildInputData";
import _apiToSDKKeyMap from "../util/apiToSDKKeyMap";
var exports = {};
const Errors = _Errors;
const Request = _Request;
const Suggestion = _Suggestion;
const buildInputData = _buildInputData;
const keyTranslationFormat = _apiToSDKKeyMap.usAutocompletePro;

/**
 * This client sends lookups to the Smarty US Autocomplete Pro API, <br>
 *     and attaches the suggestions to the appropriate Lookup objects.
 */
class Client {
  constructor(sender) {
    this.sender = sender;
  }
  send(lookup) {
    if (typeof lookup === "undefined") throw new Errors.UndefinedLookupError();
    let request = new Request();
    request.parameters = buildInputData(lookup, keyTranslationFormat);
    return new Promise((resolve, reject) => {
      this.sender.send(request).then(response => {
        if (response.error) reject(response.error);
        lookup.result = buildSuggestionsFromResponse(response.payload);
        resolve(lookup);
      }).catch(reject);
    });
    function buildSuggestionsFromResponse(payload) {
      if (payload.suggestions === null) return [];
      return payload.suggestions.map(suggestion => new Suggestion(suggestion));
    }
  }
}
exports = Client;
export default exports;