import _Batch from "./src/Batch";
import _ClientBuilder from "./src/ClientBuilder";
import _buildClients from "./src/util/buildClients";
import _SharedCredentials from "./src/SharedCredentials";
import _StaticCredentials from "./src/StaticCredentials";
import _Errors from "./src/Errors";
import _Lookup from "./src/us_street/Lookup";
import _Candidate from "./src/us_street/Candidate";
import _Lookup2 from "./src/us_zipcode/Lookup";
import _Result from "./src/us_zipcode/Result";
import _Lookup3 from "./src/us_autocomplete/Lookup";
import _Suggestion from "./src/us_autocomplete/Suggestion";
import _Lookup4 from "./src/us_autocomplete_pro/Lookup";
import _Suggestion2 from "./src/us_autocomplete_pro/Suggestion";
import _Lookup5 from "./src/us_extract/Lookup";
import _Result2 from "./src/us_extract/Result";
import _Lookup6 from "./src/international_street/Lookup";
import _Candidate2 from "./src/international_street/Candidate";
import _Lookup7 from "./src/us_reverse_geo/Lookup";
import _Lookup8 from "./src/international_address_autocomplete/Lookup";
import _Suggestion3 from "./src/international_address_autocomplete/Suggestion";
var exports = {};
exports = {
  core: {
    Batch: _Batch,
    ClientBuilder: _ClientBuilder,
    buildClient: _buildClients,
    SharedCredentials: _SharedCredentials,
    StaticCredentials: _StaticCredentials,
    Errors: _Errors
  },
  usStreet: {
    Lookup: _Lookup,
    Candidate: _Candidate
  },
  usZipcode: {
    Lookup: _Lookup2,
    Result: _Result
  },
  usAutocomplete: {
    Lookup: _Lookup3,
    Suggestion: _Suggestion
  },
  usAutocompletePro: {
    Lookup: _Lookup4,
    Suggestion: _Suggestion2
  },
  usExtract: {
    Lookup: _Lookup5,
    Result: _Result2
  },
  internationalStreet: {
    Lookup: _Lookup6,
    Candidate: _Candidate2
  },
  usReverseGeo: {
    Lookup: _Lookup7
  },
  internationalAddressAutocomplete: {
    Lookup: _Lookup8,
    Suggestion: _Suggestion3
  }
};
export default exports;